<template>
  <output
    data-el="ui-toast-message"
    data-test="ui-toast-message"
    :data-variant="props.type"
    class="pointer-events-auto flex min-h-12 w-full max-w-full overflow-hidden rounded-xl bg-white shadow-lg sm:w-max sm:max-w-xl"
    aria-live="polite"
    role="status"
  >
    <div
      class="flex items-center p-2.5 text-white"
      :class="[notificationClasses.background]"
    >
      <UiIcon
        v-if="notificationIcon.toast"
        v-bind="notificationIcon.toast"
        class="text-white"
      />
    </div>
    <div class="flex flex-1 items-center justify-between gap-4 p-4">
      <!-- only text message, without HTML -->

      <HtmlParser
        v-if="props?.message"
        :html="props.message"
        class="text-sm xl:line-clamp-3"
        :class="[notificationClasses.message]"
      />
      <UiButton
        v-if="closable"
        aria-label="close"
        class="group !block shrink-0 !text-primary"
        variant="icon"
        @click.prevent="close"
      >
        <UiIcon
          aria-hidden="true"
          name="close"
          class="transition-colors group-hover:text-blue-150"
          :width="12"
          :height="12"
        />
      </UiButton>
    </div>
  </output>
</template>

<script lang="ts" setup>
import { onUnmounted, ref } from 'vue'
import UiButton from '../UiButton/UiButton.vue'
import UiIcon from '../UiIcon/UiIcon.vue'
import { useNotificationStyles } from '../../composables/useNotificationsUi'
import type { Notification } from '../../composables/useNotificationsUi'

const props = withDefaults(defineProps<Notification>(), {
  id: Math.floor(Math.random() * Math.floor(Math.random() * Date.now())),
  type: 'info',
  closable: true,
  timeout: 3000,
})

const emit = defineEmits<{
  (e: 'close', value: Pick<Notification, 'id' | 'type'>): void
}>()

const { notificationIcon, notificationClasses } = useNotificationStyles(
  props.type,
)

const internalTimeout = ref<number>()

if (props.timeout) {
  internalTimeout.value = setTimeout(() => close(), props.timeout)
}

function close() {
  emit('close', { id: props.id, type: props.type })
}

onUnmounted(() => {
  if (props.timeout) {
    clearTimeout(internalTimeout.value)
  }
})
</script>
